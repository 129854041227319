
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ecclesia-frontend-primary: mat.define-palette(mat.$indigo-palette);
$ecclesia-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ecclesia-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ecclesia-frontend-theme: mat.define-light-theme((
  color: (
    primary: $ecclesia-frontend-primary,
    accent: $ecclesia-frontend-accent,
    warn: $ecclesia-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ecclesia-frontend-theme);
@import "./assets/css/pixeladmin.min.css";
@import "./assets/scss/var.scss";

@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
body{
    margin:0;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-header-cell {
  font-size: 14px;
  color: #fff;
}

.table-style-1{
  tr.mat-header-row {
    background-color: $color3;
    color: #fff;
}
}

.table3{

  .mat-header-cell{
      background-color: $color3;
      color: #fff;
      .mat-icon{
          cursor: pointer;
      }
  }
  .actions-header{
      display: flex;
      align-items: center;
      justify-content: space-around;
      span{
          min-width: 100px;
      }
      .mat-icon{
        background-color: #fff;
        border-radius: 50%;
        color: $color3;
      }
  }
  .icons-actions{
      .mat-icon{
          color: $color3;
      }

  }
  .page{padding-top: 15px;}
}
.table2{

  .mat-header-cell{
      background-color: $color2;
      color: #fff;
      .mat-icon{
          cursor: pointer;
      }
  }
  .actions-header{
      display: flex;
      align-items: center;
      justify-content: space-around;
      span{
          min-width: 100px;
      }
      .mat-icon{
        background-color: #fff;
        border-radius: 50%;
        color: $color1;
      }
  }
  .icons-actions{
      .mat-icon{
          color: $color1;
      }

  }
  .page{padding-top: 15px;}
}
.table1{

  .mat-header-cell{
      background-color: $color1;
      color: #fff;
      .mat-icon{
          cursor: pointer;
      }
  }
  .actions-header{
      display: flex;
      align-items: center;
      justify-content: space-around;
      span{
          min-width: 100px;
      }
      .mat-icon{
        background-color: #fff;
        border-radius: 50%;
        color: $color1;
      }
  }
  .icons-actions{
      .mat-icon{
          color: $color1;
      }

  }
  .page{padding-top: 15px;}
}
.media-only{display: none;}
.print-only{
  display: none;
  padding: 10px 20px;
  div{
    display: flex;
    justify-content: space-between;
  }
}
footer{
  position: absolute;
  bottom: 0px;
}

@media print{
  body {-webkit-print-color-adjust: exact;}
  .label-ingreso, .label-egreso{background-color: transparent!important;}
  .media-only{display:block !important;}
  .container{
    max-width: none!important;
    padding: 0px 0px!important;
    
    padding-bottom: 40px!important;
  }
  .print-only{
    display: block;
  }
  .mat-elevation-z8{
    box-shadow: none!important;
  }
  nav{
    display: none!important;
    
  }
  .no-media{
    display: none !important;
  }
  .mat-header-cell{
    background-color: $color3!important;
    color: #fff!important;
  }
  .col-md-6{
    float: left;
    width: 48%;
    margin-left: 1%;
  }
  .header{
    padding: 0px 0px!important ;
    .mat-icon{
      display: none!important;
    }
  }
  tr.mat-row, tr.mat-footer-row{
    height: 25px!important;
  }
  tr.mat-header-row {
    height: 22px!important;
    background-color: transparent!important;
  }
  .mat-header-cell{
    color: #000!important;
    font-weight: 600;
    background-color: #fff!important;
  }
  .category{
    font-weight: 600!important;
  }
  table label{
    color: #000!important;
  }
  .mat-typography h2, h3, h4{
    font-size: 16px!important;
    margin-bottom: 0px!important;
  }
  .page{
    margin-top: 0px!important;
    padding-top: 0px!important;
    margin-bottom: 0px!important;
  }
  table td{
    padding: 0px!important;
  }
  .tables-container{
    padding: 0px 0px!important;
    
  }
}